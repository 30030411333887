<template>
	<div class="login-button">
		<a
			v-if="!isOnline"
			href="/account/details"
			class="apercu-medium let-spacing-1 account-icon-login"
		>
			<span>{{ loginButtonText }}</span>
		</a>
		<a
			class="apercu-medium let-spacing-1 account-icon-logout no-arrow"
			v-else
			@click="logoutLocal"
		>
			<span>{{ loginButtonText }}</span>
		</a>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	name: 'LoginButtonMobile',
	computed: {
		...mapGetters('user', ['isOnline']),
		loginButtonText() {
			return this.isOnline ? 'Log out' : 'Log in'
		},
	},
	methods: {
		...mapActions('user', ['logout']),
		logoutLocal() {
      if(window.location.pathname.startsWith('/account')){
        this.logout({ callback: this.goLogin })
        this.$router.push('/login')
        window.location.reload()
      }else{
  			this.logout()
      }
		},
    goLogin(response) {
			if (response.status === 'ok') this.$router.push('/login')
		},
	},
}
</script>

<style></style>
